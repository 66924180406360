import React, { useEffect, useRef, useState } from "react";
import "./Productspage.css";
import icon1 from "../assets/image/LEFT_ARROW.png";
import icon2 from "../assets/image/Right_ARROW.png";

const Productspage = () => {
  const widgetsContentRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollRight = () => {
    const currentScrollLeft = widgetsContentRef.current.scrollLeft;
    widgetsContentRef.current.scrollTo({
      left: currentScrollLeft + 200,
      behavior: "smooth",
    });
  };

  const scrollLeft = () => {
    const currentScrollLeft = widgetsContentRef.current.scrollLeft;
    widgetsContentRef.current.scrollTo({
      left: currentScrollLeft - 200,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const navLinks = document.querySelectorAll(".Active");
    navLinks.forEach((link) => {
      if (link.getAttribute("href") === currentPath) {
        link.classList.add("active");
        setScrollPosition(link.parentElement.offsetLeft);
      }
    });
  }, []);

  useEffect(() => {
    widgetsContentRef.current.scrollTo({
      left: scrollPosition - 145,
      behavior: "smooth",
    });
  }, [scrollPosition]);

  return (
    <div>
      <header>
        <div className="container">
          <div className="Header_Menu">
            <div className="Scroll-Button-mobile-left">
              <button onClick={scrollLeft}>
                <img src={icon1} alt="Left Arrow" />
              </button>
            </div>
            <div className="Wrapper" ref={widgetsContentRef}>
              <div className="Menu_Box">
                <span>
                  <a href="/facesense.html" className="Active">
                    Facesense
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a href="/smartcity/vigilar.html" className="Active">
                    Vigilar
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a href="/edumetrics" className="Active">
                    Edumetrics
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a href="/anpr.html" className="Active">
                    ANPR
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a href="/ezparking" className="Active">
                    EZ Parking
                  </a>
                </span>
              </div>
              {/* <div className="Menu_Box">
                <span>
                  <a href="/cctv/perimetrion.html" className="Active">
                    Perimetrion
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a href="/smartcity/octasense.html" className="Active">
                    Octasense
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a href="/react.html" className="Active">
                    REACT
                  </a>
                </span>
              </div>*/}
            </div>
            <div className="Scroll-Button-mobile-Right">
              <button onClick={scrollRight}>
                <img src={icon2} alt="Right Arrow" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Productspage;
