import React, { useEffect, useRef, useState } from "react";
import "./EProductspage.css";
import icon1 from "../assets/image/LEFT_ARROW.png";
import icon2 from "../assets/image/Right_ARROW.png";

const EProductspage = () => {
  const widgetsContentRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const scrollRight = () => {
    const currentScrollLeft = widgetsContentRef.current.scrollLeft;
    widgetsContentRef.current.scrollTo({
      left: currentScrollLeft + 200,
      behavior: "smooth",
    });
  };

  const scrollLeft = () => {
    const currentScrollLeft = widgetsContentRef.current.scrollLeft;
    widgetsContentRef.current.scrollTo({
      left: currentScrollLeft - 200,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const currentPath = window.location.pathname;
    const navLinks = document.querySelectorAll(".Active");
    navLinks.forEach((link) => {
      if (link.getAttribute("href") === currentPath) {
        link.classList.add("active");
        setScrollPosition(link.parentElement.offsetLeft);
      }
    });
  }, []);

  useEffect(() => {
    widgetsContentRef.current.scrollTo({
      left: scrollPosition - 145,
      behavior: "smooth",
    });
  }, [scrollPosition]);

  return (
    <div>
      <header>
        <div className="container">
          <div className="Header_Menu">
            <div className="Scroll-Button-mobile-left">
              <button onClick={scrollLeft}>
                <img src={icon1} alt="Left Arrow" />
              </button>
            </div>
            <div className="wrapper" ref={widgetsContentRef}>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/face-recognition-time-attendance-System.php"
                    className="Active"
                  >
                    Face Recognition
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/videoanalytics/facerecognition/attendance-management-system-educational-institution.php"
                    className="Active"
                  >
                    Time & Attendance
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/videoanalytics/ppedetection/pharma.html"
                    className="Active"
                  >
                    Safety Gear Violation
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a href="/admetrics" className="Active">
                    Admetrics
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/videoanalytics/object-detection.html"
                    className="Active"
                  >
                    Object Detection & Counting
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/cctv/retailstores/people-counting.html"
                    className="Active"
                  >
                    Retailytics
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/videosurveillance/publictransport.html"
                    className="Active"
                  >
                    Driver Alert System
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a href="/Custom-Made-Video-Analytics" className="Active">
                    Custom Made Analytics
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/temperature-tracking-camera/covid-19/thermography.html"
                    className="Active"
                  >
                    Thermography
                  </a>
                </span>
              </div>
              <div className="Menu_Box">
                <span>
                  <a
                    href="/Intelligent-Traffic-Management-System"
                    className="Active"
                  >
                    ITMS
                  </a>
                </span>
              </div>
            </div>

            <div className="Scroll-Button-mobile-Right">
              <button onClick={scrollRight}>
                <img src={icon2} alt="Right Arrow" />
              </button>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default EProductspage;
