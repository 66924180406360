import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Homepage from "./Homepage/Homepage";
import VideoAnalyticspage from "./VideoAnalyticspage/VideoAnalyticspage";
import Aboutpage from "./Aboutpage/Aboutpage";
import Partnerspage from "./Partnerspage/Partnerspage";
import Careerspage from "./Careerspage/Careerspage";
import Contactpage from "./Contactpage/Contactpage";
import Errorpage from "./Errorpage/Errorpage";
import Face from "./LandingPages/Facesense/Facesense/Face";
import Vigilar from "./LandingPages/Vigilar/Vigilar";
import Edumetrics from "./LandingPages/Edumetrics/Edumetrics";
import ANPR from "./LandingPages/ANPR/ANPR";
import Ezparking from "./LandingPages/EZParking/Ezparking";
// import Perimetrion from "./LandingPages/Perimetrion/Perimetrion";
// import Octasense from "./LandingPages/Octasense/Octasense";
// import REACT from "./LandingPages/REACT/REACT";
import FaceAttendance from "./LandingPages/FaceAttendance/FaceAttendance";
import Time from "./LandingPages/Time&Attendance/Time";
import SafetyGear from "./LandingPages/SafetyGear/SafetyGear";
import Admetrics from "./LandingPages/Admetrics/Admetrics";
import Object from "./LandingPages/Object-Detection/Object";
import Retailytics from "./LandingPages/Retailytics/Retailytics";
import DriverAlert from "./LandingPages/DriverAlertSystem/DriverAlert";
import CustomMade from "./LandingPages/CustomMadeAnalytics/CustomMade";
import Thermography from "./LandingPages/Thermography/Thermography";
import ITMS from "./LandingPages/ITMS/ITMS";
import FaceAhmadabad from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Ahmadabad.php/FaceAhmadabad";
import FaceBengaluru from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Bengaluru.php/FaceBengaluru";
import FaceBhuvaneshwar from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Bhuvaneshwar.php/FaceBuvaneshwar";
import FaceBhopal from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Bhopal.php/FaceBhopal";
import FaceChandigarh from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Chandigarh.php/FaceChandigarh";
import FaceChennai from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Chennai.php/FaceChennai";
import FaceDelhi from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Delhi.php/FaceDelhi";
import FaceGoa from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Goa.php/FaceGoa";
import FaceHyderabad from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Hyderabad.php/FaceHyderabad";
import FaceJaipur from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Jaipur.php/FaceJaipur";
import FaceKochi from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Kochi.php/FaceKochi";
import FaceKolkata from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Kolkata.php/FaceKolkata";
import FaceMangalore from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Mangalore.php/FaceMangalore";
import FaceMumbai from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Mumbai.php/FaceMumbai";
import FacePatna from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Patna.php/FacePatna";
import FacePune from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Pune.php/FacePune";
import FaceAbuDhabi from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Abu-Dhabi.html/FaceAbuDhabi";
import FaceAustralia from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Australia.html/FaceAustralia";
import FaceBangladesh from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Bangladesh.html/FaceBangladesh";
import FaceDubai from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Dubai.html/FaceDubai";
import FaceEthiopia from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Ethiopia.html/FaceEthiopia";
import FaceKenya from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Kenya.html/FaceKenya";
import FaceMuscat from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Muscat.html/FaceMuscat";
import FaceSingapore from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Singapore.html/FaceSingapore";
import FaceSouthAfrica from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-South-Africa.html/FaceSouthAfrica";
import FaceZimbabwe from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Zimbabwe.html/FaceZimbabwe";
import FaceEgypt from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Egypt.html/FaceEgypt";
import FaceNepal from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Nepal.html/FaceNepal";
import FaceNewZealand from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-New-Zealand.html/FaceNewZealand";
import FaceNigeria from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Nigeria.html/FaceNigeria";
import FaceSriLanka from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-SriLanka.html/FaceSriLanka";
import FaceThailand from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Thailand.html/FaceThailand";
import FaceUganda from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Uganda.html/FaceUganda";
import FaceBrazil from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Brazil.html/FaceBrazil";
import FaceIndonesia from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Indonesia.html/FaceIndonesia";
import FaceIreland from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Ireland.html/FaceIreland";
import FaceMexico from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Mexico.html/FaceMexico";
import FacePhilippines from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-Philippines.html/FacePhilippines";
import FaceUK from "./LandingPages/Facesense_Old/face-recognition-time-attendance-System-UK.html/FaceUK";
import FaceKuwait from "./LandingPages/Facesense/face-recognition-time-attendance-System-Kuwait/FaceKuwait";
import FaceMalaysia from "./LandingPages/Facesense/face-recognition-time-attendance-System-Malaysia/FaceMalaysia";
import FaceQatar from "./LandingPages/Facesense/face-recognition-time-attendance-System-Qatar/FaceQatar";
import FaceSaudi from "./LandingPages/Facesense/face-recognition-time-attendance-System-Saudi-Arabia/FaceSaudi";
import FaceTurkey from "./LandingPages/Facesense/face-recognition-time-attendance-System-Turkey/FaceTurkey";
import FaceVietnam from "./LandingPages/Facesense/face-recognition-time-attendance-System-Vietnam/FaceVietnam";
import FaceAlgeria from "./LandingPages/Facesense/face-recognition-time-attendance-System-Algeria/FaceAlgeria";
import FaceAngola from "./LandingPages/Facesense/face-recognition-time-attendance-System-Angola/FaceAngola";
import FaceGhana from "./LandingPages/Facesense/face-recognition-time-attendance-System-Ghana/FaceGhana";
import FaceMorocco from "./LandingPages/Facesense/face-recognition-time-attendance-System-Morocco/FaceMorocco";
import FaceMozambique from "./LandingPages/Facesense/face-recognition-time-attendance-System-Mozambique/FaceMozambique";
import FaceSudan from "./LandingPages/Facesense/face-recognition-time-attendance-System-Sudan/FaceSudan";
import FaceAdelaide from "./LandingPages/Facesense/face-recognition-time-attendance-System-Adlaide/FaceAdlaide";
import FaceBrisbane from "./LandingPages/Facesense/face-recognition-time-attendance-System-Brisbane/FaceBrisbane";
import FaceSydney from "./LandingPages/Facesense/face-recognition-time-attendance-System-Sydney/FaceSydney";
import FacePerth from "./LandingPages/Facesense/face-recognition-time-attendance-System-Perth/FacePerth";
import FaceLondon from "./LandingPages/Facesense/face-recognition-time-attendance-System-London/FaceLondon";
import FaceMelbourne from "./LandingPages/Facesense/face-recognition-time-attendance-System-Melbourne/FaceMelbourne";
import FaceKingston from "./LandingPages/Facesense/face-recognition-time-attendance-System-Kingston/FaceKingston";
import FaceManchester from "./LandingPages/Facesense/face-recognition-time-attendance-System-Manchester/FaceManchester";
import FaceBirmingham from "./LandingPages/Facesense/face-recognition-time-attendance-System-Birmingham/FaceBirmingham";
import FaceCharlottetown from "./LandingPages/Facesense/face-recognition-time-attendance-System-Charlottetown/FaceCharlottetown";
import FaceNottingham from "./LandingPages/Facesense/face-recognition-time-attendance-System-Nottingham/FaceNottingham";
import FaceToronto from "./LandingPages/Facesense/face-recognition-time-attendance-System-Toronto/FaceToronto";
import FaceHouston from "./LandingPages/Facesense/face-recognition-time-attendance-System-Houston/FaceHouston";
import FaceNewYork from "./LandingPages/Facesense/face-recognition-time-attendance-System-New-York/FaceNewYork";
import FacePhoenix from "./LandingPages/Facesense/face-recognition-time-attendance-System-Phoenix/FacePhoenix";
import FaceLosAngeles from "./LandingPages/Facesense/face-recognition-time-attendance-System-Los-Angeles/FaceLosAngeles";
import FaceOttawa from "./LandingPages/Facesense/face-recognition-time-attendance-System-Ottawa/FaceOttawa";
import FaceChicago from "./LandingPages/Facesense/face-recognition-time-attendance-System-Chicago/FaceChicago";
import FaceBristol from "./LandingPages/Facesense/face-recognition-time-attendance-System-Bristol/FaceBristol";
import FaceGlasgow from "./LandingPages/Facesense/face-recognition-time-attendance-System-Glasgow/FaceGlasgow";
import FaceEdinburgh from "./LandingPages/Facesense/face-recognition-time-attendance-System-Edinburgh/FaceEdinburgh";
import FaceFortWorth from "./LandingPages/Facesense/face-recognition-time-attendance-System-Fort-Worth/FaceFortWorth";
import FaceLiverpool from "./LandingPages/Facesense/face-recognition-time-attendance-System-Liverpool/FaceLiverpool";
import FaceMiami from "./LandingPages/Facesense/face-recognition-time-attendance-System-Miami/FaceMiami";
import FaceIndianapolis from "./LandingPages/Facesense/face-recognition-time-attendance-System-Indianapolis/FaceIndianapolis";
import FacePhiladelphia from "./LandingPages/Facesense/face-recognition-time-attendance-System-Philadelphia/FacePhiladelphia";
import FaceShimla from "./LandingPages/Facesense/face-recognition-time-attendance-System-Shimla/FaceAmaravati";
import FaceVisakhapatnam from "./LandingPages/Facesense/face-recognition-time-attendance-System-Visakhapatnam/FaceAmaravati";
import FaceVijaywada from "./LandingPages/Facesense/face-recognition-time-attendance-System-Vijaywada/FaceAmaravati";
import FaceTumakuru from "./LandingPages/Facesense/face-recognition-time-attendance-System-Tumakuru/FaceTumakuru";
import FaceThiruvananthapuram from "./LandingPages/Facesense/face-recognition-time-attendance-System-Thiruvananthapuram/FaceAmaravati";
import FaceSurat from "./LandingPages/Facesense/face-recognition-time-attendance-System-Surat/FaceAmaravati";
import FaceSanDiego from "./LandingPages/Facesense/face-recognition-time-attendance-System-San-Diego/FaceSanDiego";
import FaceSanAntonio from "./LandingPages/Facesense/face-recognition-time-attendance-System-San-Antonio/FaceSanAntonio";
import FaceShivamoga from "./LandingPages/Facesense/face-recognition-time-attendance-System-Shivamoga/FaceAmaravati";
import FaceRanchi from "./LandingPages/Facesense/face-recognition-time-attendance-System-Ranchi/FaceAmaravati";
import FacePimpareChinchwad from "./LandingPages/Facesense/face-recognition-time-attendance-System-Pimpare-Chinchwad/FaceAmaravati";
import FaceMysore from "./LandingPages/Facesense/face-recognition-time-attendance-System-Mysore/FaceAmaravati";
import FaceMadurai from "./LandingPages/Facesense/face-recognition-time-attendance-System-Madurai/FaceAmaravati";
import FaceLucknow from "./LandingPages/Facesense/face-recognition-time-attendance-System-Lucknow/FaceAmaravati";
import FaceItanagar from "./LandingPages/Facesense/face-recognition-time-attendance-System-Itanagar/FaceAmaravati";
import FaceImphal from "./LandingPages/Facesense/face-recognition-time-attendance-System-Imphal/FaceAmaravati";
import FaceHosur from "./LandingPages/Facesense/face-recognition-time-attendance-System-Hosur/FaceAmaravati";
import FaceGulbarga from "./LandingPages/Facesense/face-recognition-time-attendance-System-Gulbarga/FaceAmaravati";
import FaceGandhinagar from "./LandingPages/Facesense/face-recognition-time-attendance-System-Gandhinagar/FaceAmaravati";
import FaceDispur from "./LandingPages/Facesense/face-recognition-time-attendance-System-Dispur/FaceAmaravati";
import FaceCoimbatore from "./LandingPages/Facesense/face-recognition-time-attendance-System-Coimbatore/FaceAmaravati";
import FaceBellary from "./LandingPages/Facesense/face-recognition-time-attendance-System-Bellary/FaceAmaravati";
import FaceBelgaum from "./LandingPages/Facesense/face-recognition-time-attendance-System-Belgaum/FaceAmaravati";
import FaceAustin from "./LandingPages/Facesense/face-recognition-time-attendance-System-Austin/FaceAustin";
import FaceSeattle from "./LandingPages/Facesense/face-recognition-time-attendance-System-Seattle/FaceSeattle";
import FaceWashingtonDC from "./LandingPages/Facesense/face-recognition-time-attendance-System-Washington-DC/FaceWasingtonDC";
import FaceLasVegas from "./LandingPages/Facesense/face-recognition-time-attendance-System-Las-Vegas/FaceLasVegas";
import FaceAmaravati from "./LandingPages/Facesense/face-recognition-time-attendance-System-Amaravati/FaceAmaravati";
import FaceHubliDharward from "./LandingPages/Facesense/face-recognition-time-attendance-System-Hubli-Darwar/FaceAmaravati";
const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/Video-analytics" element={<VideoAnalyticspage />} />
          <Route path="/facesense.html" element={<Face />} />
          <Route path="/smartcity/vigilar.html" element={<Vigilar />} />
          <Route path="/edumetrics" element={<Edumetrics />} />
          <Route path="/anpr.html" element={<ANPR />} />
          <Route path="/ezparking" element={<Ezparking />} />
          {/* <Route path="/cctv/perimetrion.html" element={<Perimetrion />} /> */}
          {/* <Route path="/smartcity/octasense.html" element={<Octasense />} /> */}
          {/* <Route path="/react.html" element={<REACT />} /> */}
          <Route path="/aboutus.html" element={<Aboutpage />} />
          <Route path="/partners.html" element={<Partnerspage />} />
          <Route path="/jobs.html" element={<Careerspage />} />
          <Route path="/reachus.html" element={<Contactpage />} />
          <Route
            path="/Intelligent-Traffic-Management-System"
            element={<ITMS />}
          />
          <Route
            path="/temperature-tracking-camera/covid-19/thermography.html"
            element={<Thermography />}
          />
          <Route path="/Custom-Made-Video-Analytics" element={<CustomMade />} />
          <Route
            path="/cctv/retailstores/people-counting.html"
            element={<Retailytics />}
          />
          <Route
            path="/videosurveillance/publictransport.html"
            element={<DriverAlert />}
          />
          <Route path="/admetrics" element={<Admetrics />} />
          <Route
            path="/videoanalytics/ppedetection/pharma.html"
            element={<SafetyGear />}
          />
          <Route
            path="/videoanalytics/object-detection.html"
            element={<Object />}
          />
          <Route
            path="/videoanalytics/facerecognition/attendance-management-system-educational-institution.php"
            element={<Time />}
          />
          <Route
            path="/face-recognition-time-attendance-System.php"
            element={<FaceAttendance />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Ahmadabad.php"
            element={<FaceAhmadabad />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Bengaluru.php"
            element={<FaceBengaluru />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Bhopal.php"
            element={<FaceBhopal />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Bhuvaneshwar.php"
            element={<FaceBhuvaneshwar />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Chandigarh.php"
            element={<FaceChandigarh />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Chennai.php"
            element={<FaceChennai />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Delhi.php"
            element={<FaceDelhi />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Goa.php"
            element={<FaceGoa />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Hyderabad.php"
            element={<FaceHyderabad />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Jaipur.php"
            element={<FaceJaipur />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Kochi.php"
            element={<FaceKochi />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Kolkata.php"
            element={<FaceKolkata />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Mangalore.php"
            element={<FaceMangalore />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Mumbai.php"
            element={<FaceMumbai />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Patna.php"
            element={<FacePatna />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Pune.php"
            element={<FacePune />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Abu-Dhabi.html"
            element={<FaceAbuDhabi />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Australia.html"
            element={<FaceAustralia />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Bangladesh.html"
            element={<FaceBangladesh />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Dubai.html"
            element={<FaceDubai />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Ethiopia.html"
            element={<FaceEthiopia />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Kenya.html"
            element={<FaceKenya />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Muscat.html"
            element={<FaceMuscat />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Singapore.html"
            element={<FaceSingapore />}
          />
          <Route
            path="/face-recognition-time-attendance-System-South-Africa.html"
            element={<FaceSouthAfrica />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Zimbabwe.html"
            element={<FaceZimbabwe />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Egypt.html"
            element={<FaceEgypt />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Nepal.html"
            element={<FaceNepal />}
          />
          <Route
            path="/face-recognition-time-attendance-System-New-Zealand.html"
            element={<FaceNewZealand />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Nigeria.html"
            element={<FaceNigeria />}
          />
          <Route
            path="/face-recognition-time-attendance-System-SriLanka.html"
            element={<FaceSriLanka />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Thailand.html"
            element={<FaceThailand />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Uganda.html"
            element={<FaceUganda />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Brazil.html"
            element={<FaceBrazil />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Indonesia.html"
            element={<FaceIndonesia />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Ireland.html"
            element={<FaceIreland />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Mexico.html"
            element={<FaceMexico />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Philippines.html"
            element={<FacePhilippines />}
          />
          <Route
            path="/face-recognition-time-attendance-System-UK.html"
            element={<FaceUK />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Kuwait"
            element={<FaceKuwait />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Malaysia"
            element={<FaceMalaysia />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Qatar"
            element={<FaceQatar />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Saudi-Arabia"
            element={<FaceSaudi />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Turkey"
            element={<FaceTurkey />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Vietnam"
            element={<FaceVietnam />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Algeria"
            element={<FaceAlgeria />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Angola"
            element={<FaceAngola />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Ghana"
            element={<FaceGhana />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Morocco"
            element={<FaceMorocco />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Mozambique"
            element={<FaceMozambique />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Sudan"
            element={<FaceSudan />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Adelaide"
            element={<FaceAdelaide />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Brisbane"
            element={<FaceBrisbane />}
          />
          <Route
            path="/face-recognition-time-attendance-System-London"
            element={<FaceLondon />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Melbourne"
            element={<FaceMelbourne />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Perth"
            element={<FacePerth />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Sydney"
            element={<FaceSydney />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Kingston"
            element={<FaceKingston />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Manchester"
            element={<FaceManchester />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Birmingham"
            element={<FaceBirmingham />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Toronto"
            element={<FaceToronto />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Charlottetown"
            element={<FaceCharlottetown />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Nottingham"
            element={<FaceNottingham />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Chicago"
            element={<FaceChicago />}
          />
          <Route
            path="/face-recognition-time-attendance-System-New-York"
            element={<FaceNewYork />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Los-Angeles"
            element={<FaceLosAngeles />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Phoenix"
            element={<FacePhoenix />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Houston"
            element={<FaceHouston />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Ottawa"
            element={<FaceOttawa />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Bristol"
            element={<FaceBristol />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Edinburgh"
            element={<FaceEdinburgh />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Fort-Worth"
            element={<FaceFortWorth />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Glasgow"
            element={<FaceGlasgow />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Indianapolis"
            element={<FaceIndianapolis />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Liverpool"
            element={<FaceLiverpool />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Miami"
            element={<FaceMiami />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Philadelphia"
            element={<FacePhiladelphia />}
          />
          <Route
            path="/face-recognition-time-attendance-System-San-Antonio"
            element={<FaceSanAntonio />}
          />
          <Route
            path="/face-recognition-time-attendance-System-San-Diego"
            element={<FaceSanDiego />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Austin"
            element={<FaceAustin />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Seattle"
            element={<FaceSeattle />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Washington-DC"
            element={<FaceWashingtonDC />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Las-Vegas"
            element={<FaceLasVegas />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Amaravati"
            element={<FaceAmaravati />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Belgaum"
            element={<FaceBelgaum />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Bellary"
            element={<FaceBellary />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Coimbatore"
            element={<FaceCoimbatore />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Dispur"
            element={<FaceDispur />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Gandhinagar"
            element={<FaceGandhinagar />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Gulbarga"
            element={<FaceGulbarga />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Hosur"
            element={<FaceHosur />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Hubli-Dharwad"
            element={<FaceHubliDharward />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Imphal"
            element={<FaceImphal />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Itanagar"
            element={<FaceItanagar />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Lucknow"
            element={<FaceLucknow />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Madurai"
            element={<FaceMadurai />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Mysore"
            element={<FaceMysore />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Pimpare-Chinchwad"
            element={<FacePimpareChinchwad />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Ranchi"
            element={<FaceRanchi />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Shimla"
            element={<FaceShimla />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Shivamoga"
            element={<FaceShivamoga />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Surat"
            element={<FaceSurat />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Thiruvananthapuram"
            element={<FaceThiruvananthapuram />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Tumakuru"
            element={<FaceTumakuru />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Vijaywada"
            element={<FaceVijaywada />}
          />
          <Route
            path="/face-recognition-time-attendance-System-Visakhapatnam"
            element={<FaceVisakhapatnam />}
          />
          <Route path="*" element={<Errorpage />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
