import React, { useCallback, useRef } from "react";
import "./Homepage.css";
import Header from "../Header/Header";
import HomeFooter from "../HomeFooter/HomeFooter";
import HomeForm from "../HomeForm/HomeForm";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import image1 from "../assets/image/facesensebannerimg.jpg";
import image111 from "../assets/image/facesensebannerimg1.webp";
import image2 from "../assets/image/homepagetraffic.jpg";
import image3 from "../assets/image/homepageobjectdetection.jpg";
import image4 from "../assets/image/homepagesecurity.jpg";
import image5 from "../assets/image/homepageppe1.jpg";
import image6 from "../assets/image/homepageadmetrics.jpg";
import image7 from "../assets/image/homepageautomate.jpg";
import image8 from "../assets/image/homepagetemp.jpg";
import image9 from "../assets/image/AWARD-01.svg";
import image10 from "../assets/image/AWARD-02.svg";
import image11 from "../assets/image/AWARD-03.svg";
import image12 from "../assets/image/Products_brochure_arrow.svg";
import image13 from "../assets/image/home_page_custom.png";
import icon from "../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon4 from "../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon1 from "../assets/PNG/LEFT.png";
import icon2 from "../assets/PNG/Right.png";
import icon3 from "../assets/PNG/close.png";
import logo1 from "../assets/Our_Brand/Our_Brand_1.svg";
import logo2 from "../assets/Our_Brand/Our_Brand_2.svg";
import logo3 from "../assets/Our_Brand/Our_Brand_3.svg";
import logo4 from "../assets/Our_Brand/Our_Brand_4.svg";
import logo5 from "../assets/Our_Brand/Our_Brand_5.svg";
import logo6 from "../assets/Our_Brand/Our_Brand_6.svg";
import logo7 from "../assets/Our_Brand/Our_Brand_7.svg";
import logo8 from "../assets/Our_Brand/Our_Brand_8.svg";
import logo9 from "../assets/Our_Brand/Our_Brand_9.svg";
import logo10 from "../assets/Our_Brand/Our_Brand_10.svg";
import logo11 from "../assets/Our_Brand/Our_Brand_11.svg";
import logo12 from "../assets/Our_Brand/Our_Brand_12.svg";
import logo13 from "../assets/Our_Brand/Our_Brand_13.svg";
import request from "superagent";
import { BaseURL } from "../BaseURL";

const Homepage = () => {
  const { register, handleSubmit, reset } = useForm();
  const [mob, setNum1] = useState("");
  const [showPortfolio, setPortfolio] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const scrollContainerRef = useRef(null);
  const brandContainerRef = useRef(null);

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func(...args), delay);
    };
  };

  const handleNumChangeM = useCallback((event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    setNum1(numericValue.slice(0, 10));
  }, []);

  const onSubmit = (data) => {
    if (
      ["First_Name", "Last_Name", "Email", "Phone_Number", "Message"].some(
        (field) => register[field]?.trim() !== ""
      )
    ) {
      setPortfolio(false);
      setShowPopup(true);
      request
        .post(`${BaseURL}/Mail/Brochure`)
        .send(data)
        .set("Accept", "application/json")
        .then((response) => console.log(response.body.message))
        .catch((error) => console.error("Error sending Email:", error));

      reset();
      setNum1("");
    }
  };

  const handleClose = () => setPortfolio(false);

  const calculateScrollAmount = () => {
    const screenWidth =
      window.innerWidth || document.documentElement.clientWidth;
    return screenWidth <= 600 ? 260 : 300;
  };

  const handleScrollLeft = () => {
    brandContainerRef.current.scrollLeft -= calculateScrollAmount();
  };

  const handleScrollRight = () => {
    brandContainerRef.current.scrollLeft += calculateScrollAmount();
  };

  const handleWheelScroll = debounce((event) => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.scrollBy({
      top: event.deltaY,
      behavior: "smooth",
    });
  }, 500);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    scrollContainer.addEventListener("wheel", handleWheelScroll);

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          entry.target.classList.toggle("active", entry.isIntersecting);
        });
      },
      { root: null, threshold: 0.5 }
    );

    const elementsToObserve = document.querySelectorAll(
      ".aos, .Panel h1 span, .underline1, .underline2, .underlinew, .underline3"
    );
    elementsToObserve.forEach((el) => observer.observe(el));

    return () => {
      scrollContainer.removeEventListener("wheel", handleWheelScroll);
      observer.disconnect();
    };
  }, [handleWheelScroll]);

  useEffect(() => {
    const preloadImage = () => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = image111;
      document.head.appendChild(link);

      return () => document.head.removeChild(link);
    };

    preloadImage();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Top CCTV Camera & Video Analytics Software Company in Bangalore India
          | Safepro
        </title>
        <meta
          name="description"
          content="Safepro is top CCTV camera and video analytics software Technology Company in Bangalore, India offering edge video intelligence enabled video surveillance & electronic security systems in India.
          "
        />
        <meta
          name="keyword"
          content=" CCTV, Video analytics software, Video analytics Technology, CCTV Camera , Video Analytics Company in Bangalore, Video Analytics Company in India, CCTV in Bangalore, Video Surveillance Company in India, CCTV Surveillance in Bangalore.
          "
        />
        <link rel="canonical" href="https://www.safepro.tech/" />
      </Helmet>
      <Header />
      <div className="Body_Container_1" ref={scrollContainerRef}>
        <div className="Back_Section One ">
          <div className="Body">
            <div className="Section aos">
              <div className="Panel_C">
                <div className="Images">
                  <img src={image1} alt="" />
                </div>
              </div>
              <div className="Panel">
                <h1>
                  Turn your CCTV to
                  <br />
                  <span className="Color_Change reveal">
                    &nbsp;FACE&nbsp;
                  </span>{" "}
                  <br />
                  <span className="Color Change reveal">
                    &nbsp;RECOGNITION&nbsp;
                  </span>
                  <br />
                  Attendance Tool
                </h1>
                <p>
                  <a href="/facesense.html">Know more</a>
                </p>
              </div>
              <div className="Panel_B">
                <div className="Images">
                  <img
                    src={image111}
                    alt=""
                    loading="eager"
                    style={{ display: "block" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Back_Section Two">
          <div className="Body">
            <div className="Section aos">
              <div className="Panel_B">
                <div className="Images_B">
                  <img src={image2} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel_C">
                <div className="Images">
                  <img src={image2} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel">
                <h1>
                  Traffic <span>&nbsp;enforcement&nbsp;</span> is tough! Not
                  when you've our <span className="underline1">ITMS</span>
                </h1>
                <p>
                  <a href="/anpr.html">Know more</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Back_Section One">
          <div className="Body">
            <div className="Section aos">
              <div className="Panel_C">
                <div className="Images">
                  <img src={image3} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel">
                <h1>
                  If you can't count it, you can't &nbsp;
                  <span>&nbsp;measure it!&nbsp;</span> Object Detection &
                  Counting with{" "}
                  <span className="underline2">vision&nbsp;computing</span>
                </h1>
                <p>
                  <a href="/videoanalytics/object-detection.html">Know more</a>
                </p>
              </div>
              <div className="Panel_B">
                <div className="Images">
                  <img src={image3} alt="" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Back_Section Three">
          <div className="Body">
            <div className="Section aos">
              <div className="Panel_C">
                <div className="Images">
                  <img src={image4} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel">
                <h2>
                  Security can't be FORENSIC. It must be{" "}
                  <span className="underlinew">PREVENTIVE</span>
                </h2>
                <h3>
                  <a href="/videoanalytics/object-detection.html">Know more</a>
                </h3>
              </div>
              <div className="Panel_B">
                <div className="Images">
                  <img src={image4} alt="" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Back_Section One">
          <div className="Body">
            <div className="Section aos">
              <div className="Panel_B">
                <div className="Images_B">
                  <img src={image5} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel_C">
                <div className="Images">
                  <img src={image5} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel">
                <h1>
                  Safeguard your employees with{" "}
                  <span>&nbsp;PPE Violation&nbsp;</span> detection
                </h1>
                <p>
                  <a href="/videoanalytics/ppedetection/pharma.html">
                    Know more
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Back_Section Two">
          <div className="Body">
            <div className="Section aos">
              <div className="Panel_C">
                <div className="Images">
                  <img src={image6} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel">
                <h1>
                  Are your ADS performance measured? We can do this with&nbsp;
                  <span className="underline1">Admetrics</span>
                </h1>
                <p>
                  <a href="/admetrics">Know more</a>
                </p>
              </div>
              <div className="Panel_B">
                <div className="Images">
                  <img src={image6} alt="" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Back_Section One">
          <div className="Body">
            <div className="Section aos">
              <div className="Panel_B">
                <div className="Images_B">
                  <img src={image7} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel_C">
                <div className="Images">
                  <img src={image7} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel">
                <h1>
                  You've a <span className="underline2">process&nbsp;&</span>
                  <br />
                  <span className="underline2">pattern</span> in your
                  production? We can <span>&nbsp;AUTOMATE&nbsp;</span> it's
                  monitoring & tracking
                </h1>
                <p>
                  <a href="/Custom-Made-Video-Analytics">Know more</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Back_Section Three">
          <div className="Body">
            <div className="Section aos">
              <div className="Panel_C">
                <div className="Images">
                  <img src={image8} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel">
                <h2>
                  Temp detection of human, Temp monitoring of your core
                  machinery?{" "}
                  <span className="underlinew">
                    WE&nbsp;CAN&nbsp;DO&nbsp;IT&nbsp;ALL.
                  </span>
                </h2>
                <h3>
                  <a href="/temperature-tracking-camera/covid-19/thermography.html">
                    Know more
                  </a>
                </h3>
              </div>
              <div className="Panel_B">
                <div className="Images">
                  <img src={image8} alt="" loading="lazy" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Back_Section One">
          <div className="Body">
            <div className="Section aos">
              <div className="Panel_B">
                <div className="Images_B">
                  <img src={image13} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel_C">
                <div className="Images">
                  <img src={image13} alt="" loading="lazy" />
                </div>
              </div>
              <div className="Panel">
                <h1>
                  <p>
                    You’ve a problem statement to solve? We can solve with{" "}
                    <span style={{ whiteSpace: "nowrap" }}>
                      &nbsp;Custom-designed&nbsp;
                    </span>{" "}
                    <br /> VIDEO ANALYTICS
                  </p>{" "}
                </h1>
                <p>
                  <a href="/Custom-Made-Video-Analytics">Know more</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Body">
          <div className="OurBrand_Data">
            <div className="OurBrand_Body">
              <div className="OurBrand_Container">
                <div className="OurBrand_Heading">
                  <h1>Our Brands</h1>
                </div>
                <div className="OurBrand_Logo_container">
                  <div className="SliderButton">
                    <img
                      srcSet={icon1}
                      alt=""
                      onClick={handleScrollLeft}
                      loading="lazy"
                    />
                  </div>
                  <div
                    id="Brand_Container"
                    className="AllOur_Brand_Logo_Container"
                    ref={brandContainerRef}
                  >
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo1} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo2} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo3} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo4} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo5} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo6} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo7} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo8} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo9} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo10} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo11} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo12} alt="" loading="lazy" />
                    </div>
                    <div className="Our_Brand_Logo">
                      <img srcSet={logo13} alt="" loading="lazy" />
                    </div>
                  </div>
                  <div className="SliderButton">
                    <img
                      srcSet={icon2}
                      alt=""
                      onClick={handleScrollRight}
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Recognition_Block">
            <div className="Recognition_Body">
              <div className="Recognition">
                <div className="Recognition_Container">
                  <h1>Recognition</h1>
                  <h2>through the years</h2>
                  <h3>Media mentions‚ Industry recognition & Awards</h3>
                </div>
                <div className="Recognition_Details">
                  <div className="Recognition_Logo_Container">
                    <img srcSet={image9} alt="" loading="lazy" />
                  </div>
                  <div className="Recognition_Logo_Container">
                    <img srcSet={image10} alt="" loading="lazy" />
                  </div>
                  <div className="Recognition_Logo_Container">
                    <img srcSet={image11} alt="" loading="lazy" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="OurPortFolio">
            <div className="OurPortFolio_Block">
              <div className="Portfolio_data">
                <div className="PortFolio_Panel">
                  <h1>Explore our product portfolio!</h1>
                </div>
                <div
                  className="PortFolio_Panel"
                  onClick={() => {
                    setPortfolio(true);
                  }}
                >
                  <h2>
                    All Products Brochure <img srcSet={image12} alt="" />{" "}
                  </h2>
                </div>
              </div>
            </div>
          </div>
          {showPortfolio && (
            <>
              <div className="OurPortfolio_Form">
                <form
                  className="Form_Container"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="Close_Button">
                    <img
                      src={icon3}
                      alt="Close"
                      onClick={handleClose}
                      loading="lazy"
                    />
                  </div>
                  <div className="Form_Heading">
                    <h1>Please fill this form</h1>
                    <p>We will share the product catalog through your email.</p>
                  </div>
                  <div className="Form_InputField_Container">
                    <div className="InputField_Panel">
                      <input
                        type="text"
                        {...register("First_Name")}
                        placeholder="First Name"
                        required
                      />
                    </div>
                    <div className="InputField_Panel_A">
                      <input
                        type="text"
                        {...register("Last_Name")}
                        placeholder="Last Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="Form_InputField_Container">
                    <div className="InputField_Panel">
                      <input
                        type="email"
                        {...register("Email")}
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="InputField_Panel_A">
                      <input
                        type="number"
                        {...register("Phone_Number")}
                        placeholder="Phone Number"
                        value={mob}
                        onChange={(event) => {
                          handleNumChangeM(event);
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="Form_InputField_Container_B">
                    <textarea
                      className="HomeMessage"
                      {...register("Message")}
                      placeholder="Message"
                      required
                    />
                  </div>
                  <div className="Form_InputField_Container_A">
                    <button type="submit">Submit</button>
                  </div>
                </form>
              </div>
            </>
          )}
          {showPopup && (
            <>
              <div className="Overlay" />
              <div className="Popup" style={{ zIndex: "1000" }}>
                <img
                  src={icon4}
                  alt="Close"
                  className="CloseButton"
                  onClick={() => setShowPopup(false)}
                />
                <img src={icon} alt="Popup" loading="lazy" />
                <span>
                  <h1>Thank You</h1>
                  <h3>
                    <p>The form was submitted successfully</p>
                  </h3>
                </span>
              </div>
            </>
          )}
        </div>
        <div className="Body_1">
          <HomeForm />
          <HomeFooter />
        </div>
        <div className="Body_2">
          <HomeForm />
        </div>
        <div className="Body_2">
          <HomeFooter />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
