import React from "react";
import { useState, useEffect, useCallback } from "react";
import "./Header.css";
import logo from "../assets/image/safepro_logo.svg";

const Header = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [showMenu, setMenu] = useState(false);

  const toggleMenu = useCallback(() => {
    setIsOpened((prev) => !prev);
    setMenu((prev) => !prev);
  }, []);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const navLinks = document.querySelectorAll(".nav-bar");

    navLinks.forEach((link) => {
      if (link.getAttribute("href") === currentPath) {
        link.classList.add("active");
      } else {
        link.classList.remove("active");
      }
    });
  }, []);

  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "preload";
    link.as = "image";
    link.href = logo;
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  return (
    <div className="Header">
      <div className="Header_Elements">
        <div className="Head_Image_container">
          <a href="/">
            <img
              className="Safe_Logo"
              srcSet={logo}
              alt="Safepro"
              loading="eager"
              fetchpriority="high"
            />
          </a>
        </div>
        <div className="Navigation_Bar">
          <a className="nav-bar" href="/">
            Home
          </a>
          <a className="nav-bar" href="/Video-analytics">
            Video&nbsp;Analytics
          </a>
          <a className="nav-bar" href="/facesense.html">
            Products
          </a>
          <a className="nav-bar" href="/aboutus.html">
            About
          </a>
          <a className="nav-bar" href="/partners.html">
            Partners
          </a>
          <a className="nav-bar" href="/jobs.html">
            Careers
          </a>
          <a className="nav-bar" href="/reachus.html">
            Contact
          </a>
        </div>
      </div>
      <div className="Mobile_Header">
        <button
          className={`Horizontal_Menu ${isOpened ? "opened" : ""}`}
          onClick={toggleMenu}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              toggleMenu();
            }
          }}
          aria-label="Main Menu"
          aria-controls="menu"
          aria-expanded={isOpened}
        >
          <svg width="40" height="40" viewBox="0 0 100 100" aria-hidden="true">
            <path
              className="line line1"
              d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
            />
            <path className="line line2" d="M 20,50 H 80" />
            <path
              className="line line3"
              d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
            />
          </svg>
        </button>
      </div>
      {showMenu && (
        <>
          <div id="menu" className="Mobile_Menu">
            <div className="Menu">
              <a href="/">Home</a>
              <a href="/Video-analytics">Video Analytics</a>
              <a href="/facesense.html">Products</a>
              <a href="/aboutus.html">About</a>
              <a href="/partners.html">Partners</a>
              <a href="/jobs.html">Careers</a>
              <a href="/reachus.html">Contact</a>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Header;
