import React from "react";
import "./Errorpage.css";
import image from "../assets/image/1.jpg";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
const Errorpage = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Header />
      <div className="Background" style={{ backgroundImage: `url(${image})` }}>
        <h1>404</h1>
        <h3>Page not found</h3>
        <p>
          It seems you're encountering a "Page Not Found" issue. This typically
          occurs when a website or web page you're trying to access doesn't
          exist or has been moved or deleted.
        </p>
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          Go back
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default Errorpage;
