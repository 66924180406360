import React from "react";
import "./Aboutpage.css";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import Formpage from "../Form/Form";
import Footer from "../Footer/Footer";
import ComingSoon from "../ComingSoon/ComingSoon";
// import image from "../assets/image/about_banner.jpg";
// import icon1 from "../assets/image/Products.svg";
// import icon2 from "../assets/image/Customers.svg";
// import icon3 from "../assets/image/AI_Breakthroughs.svg";
// import icon4 from "../assets/image/Countries.svg";
// import image1 from "../assets/image/mythili.jpg";
// import image2 from "../assets/image/About_its.jpg";
// import image3 from "../assets/image/About_nitin.jpg";
// import image4 from "../assets/image/About_sujayrao.jpg";
// import image5 from "../assets/image/About_karthik.jpg";
// import image6 from "../assets/image/About_sonu.jpg";
// import image7 from "../assets/image/About_kvr.jpg";
// import image8 from "../assets/image/About_rakshita.jpg";
// import image9 from "../assets/image/neeraj.jpg";

const Aboutpage = () => {
  return (
    <div>
      <Helmet>
        <title>
          About Us - CCTV Camera Surveillance System in India | Safepro
        </title>
        <meta
          name="description"
          content="Safepro offers AI, IOT, smart security, video analytics, cctv camera surveillance, cognitive surveillance systems in India with cutting edge technology solving real problems for our customers.
          "
        />
        <meta
          name="keyword"
          content=" About Us , CCTV Camera Surveillance in India, Camera Surveillance System in India , CCTV Camera in Bangalore, Camera Surveillance in Bangalore, Video Analytics.
          "
        />
        <link rel="canonical" href="https://www.safepro.tech/aboutus.html" />
      </Helmet>
      <Header />
      {/* <div className="Section_5">
        <div className="section_5_Body_Sections">
          <div className="section_5_widths">
            <img src={image} alt="" />
          </div>
        </div>
      </div>
      <div className="section1">
        <div className="section1_Body_Section">
          <div className="Section_1_width">
            <div className="section_1_top">
              <div className="section_1_top_box">
                <h1>Who we are?</h1>
              </div>
            </div>
            <div className="section_1_bottom">
              <h1>
                Something we started out as fun making intelligent software 18
                years back quickly turned out to be a serious business of AI,
                IoT, smart security, video analytics, and cognitive surveillance
                systems with cutting-edge technology solving real problems for
                our customers.
              </h1>
              <p>
                We have been successfully making products that touches lives of
                people in more ways than we thought when we started. With a
                vision of making smart security to prevent incidents, we have
                been adhering to our vision of 'Lets prevent, not repent' with
                every product we've made.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section_3">
        <div className="section_3_Body_Section">
          <div className="section_3_widths">
            <div className="section_3_top">
              <div className="section_3_top_box">
                <h1>What we do?</h1>
              </div>
            </div>
            <div className="section_3_bottom">
              <p>
                From network cameras, video analytics, smart poles to IP
                speakers, IP intercoms, unmanned ground vehicles, we make it
                all. With a state-of-the-art AI lab and a strong research and
                development team, all products are designed and developed in
                India adhering to global quality standards.
              </p>
              <p>
                We're a team of disruptive techno-geeks working in the field of
                vision engineering for the last 18 years. With the best GPU
                infrastructure, we are equipped with NVIDIA DGX stations for our
                cognitive surveillance systems. Computer vision engineers, AI
                research scientists, embedded electronics engineers, full stack
                developers from reputed institutes like IISc, IIT, REC and
                top-notch engineers make up our team with one vision - to make
                sense of seeing.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section_">
        <div className="section_2_Body_Section">
          <div className="section_2_width_">
            <div className="section_2_body">
              <div className="section_2_body_parts1">
                <div className="body_parts1_top">
                  <img src={icon1} alt="" />
                </div>
                <div className="body_parts1_down">
                  <h1>35+</h1>
                  <p>Products</p>
                </div>
              </div>
              <div className="section_2_body_parts1">
                <div className="body_parts1_top">
                  <img src={icon2} alt="" />
                </div>
                <div className="body_parts1_down">
                  <h1>500+</h1>
                  <p>&nbsp;&nbsp;Customers</p>
                </div>
              </div>
              <div className="section_2_body_parts1">
                <div className="body_parts1_top">
                  <img src={icon3} alt="" />
                </div>
                <div className="body_parts1_down">
                  <h1>125+</h1>
                  <p>AI Breakthroughs</p>
                </div>
              </div>
              <div className="section_2_body_parts1">
                <div className="body_parts1_top">
                  <img src={icon4} alt="" />
                </div>
                <div className="body_parts1_down">
                  <h1>&nbsp;&nbsp;09</h1>
                  <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Countries</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-6">
        <div className="one">
          <li>
            <div className="square">
              <div className="content">
                <img src={image1} alt="" />
              </div>
            </div>
          </li>

          <li>
            <a href="/jobs.html">
              <div className="square">
                <div className="content">
                  <img src={image2} alt="" />
                </div>
              </div>
            </a>
          </li>

          <li>
            <div className="square">
              <div className="content">
                <img src={image3} alt="" />
              </div>
            </div>
          </li>
        </div>
        <div className="two">
          <li>
            <div className="square">
              <div className="content">
                <img src={image4} alt="" />
              </div>
            </div>
          </li>

          <li>
            <div className="square">
              <div className="content">
                <img src={image5} alt="" />
              </div>
            </div>
          </li>

          <li>
            <div className="square">
              <div className="content">
                <img src={image6} alt="" />
              </div>
            </div>
          </li>
        </div>
        <div className="three">
          <li>
            <div className="square">
              <div className="content">
                <img src={image7} alt="" />
              </div>
            </div>
          </li>

          <li>
            <div className="square">
              <div className="content">
                <img src={image8} alt="" />
              </div>
            </div>
          </li>
          <li>
            <div className="square">
              <div className="content">
                <img src={image9} alt="" />
              </div>
            </div>
          </li>
        </div>
        <div className="four">
          <li>
            <div className="square">
              <div className="content">
                <img src={image4} alt="" />
              </div>
            </div>
          </li>

          <li>
            <div className="square">
              <div className="content">
                <img src={image1} alt="" />
              </div>
            </div>
          </li>

          <li>
            <div className="square">
              <div className="content">
                <img src={image5} alt="" />
              </div>
            </div>
          </li>

          <li>
            <a href="/jobs.html">
              <div className="square">
                <div className="content">
                  <img src={image2} alt="" />
                </div>
              </div>
            </a>
          </li>

          <li>
            <div className="square">
              <div className="content">
                <img src={image7} alt="" />
              </div>
            </div>
          </li>

          <li>
            <div className="square">
              <div className="content">
                <img src={image8} alt="" />
              </div>
            </div>
          </li>

          <li>
            <div className="square">
              <div className="content">
                <img src={image6} alt="" />
              </div>
            </div>
          </li>

          <li>
            <div className="square">
              <div className="content">
                <img src={image3} alt="" />
              </div>
            </div>
          </li>
          <li>
            <div className="square">
              <div className="content">
                <img src={image9} alt="" />
              </div>
            </div>
          </li>
        </div>
      </div> */}
      <ComingSoon />
      <Formpage />
      <Footer />
    </div>
  );
};

export default Aboutpage;
