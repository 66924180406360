import React from "react";
import "./Contactpage.css";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import Formpage from "../Form/Form";
import Footer from "../Footer/Footer";

const Contactpage = () => {
  return (
    <div>
      <Helmet>
        <title>Reach Us - Safepro</title>
        <meta
          name="description"
          content="For AI, IOT, smart security, video analytics, cognitive surveillance systems, CCTV camera, body worn camera, public transport surveillance in India. To know more information contact us.
          "
        />
        <meta
          name="keyword"
          content="Reach us, Contact us ,  Safepro, Call us, Mail us
          "
        />
        <link rel="canonical" href="https://www.safepro.tech/aboutus.html" />
      </Helmet>
      <Header />
      <div className="section_1">
        <div className="section_1_Body_Section">
          <div className="section_1_width">
            <div className="section_1_first">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.6464692196723!2d77.53022561552106!3d12.99444911789071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3d3bf234f587%3A0xb0e6ac1d7a17a4b4!2sSafepro%20AI%20Video%20Research%20Labs%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1680759460088!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Safepro Location"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="section_3">
        <div className="section3_Body_Section">
          <div className="section3_width">
            <div className="section_3_box">
              <div className="section3_box_1">
                <div className="address">
                  <h1>ADDRESS</h1>
                  <a
                    href="https://www.google.com/maps/place/Safepro+AI+Video+Research+Labs+Pvt+Ltd/@12.9944452,77.5318671,19z/data=!3m1!4b1!4m5!3m4!1s0x3bae3d3bf234f587:0xb0e6ac1d7a17a4b4!8m2!3d12.994403!4d77.532402"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Safepro AI Video Research Labs Pvt. Ltd.
                  </a>
                  <p>
                    Regd Off: 122, C-Wing,3rd main Gruhalakshmi layout II stage
                    Kamalanagar, Bengaluru 560079, Karnataka India.
                  </p>
                </div>
              </div>
              <div className="line"></div>
              <div className="section3_box_2">
                <div className="call">
                  <h2>
                    BUSINESS HOURS{" "}
                    <span className="Timings">
                      (Mon&nbsp;-&nbsp;Fri.&nbsp;10AM&nbsp;-&nbsp;6PM)
                    </span>
                  </h2>
                  <a href="tel:+91 80 2323 0607">
                    <label>
                      <span>Landline</span>
                    </label>
                    <label>
                      <span>:</span>
                    </label>
                    <label>+91 80 2323 0607</label>
                  </a>
                  <a href="tel:+91 82772 82772">
                    <label>
                      <span>Mobile</span>
                    </label>
                    <label>
                      <span>:</span>
                    </label>
                    <label>+91 82772 82772</label>
                  </a>
                  {/* <a href="tel:+91 82771 01111">
                  <label><span>Support</span></label><label><span>:</span></label><label>+91 82771 01111</label>
                  </a> */}
                </div>
              </div>
              <div className="line"></div>
              <div className="section3_box_3">
                <div className="mail">
                  <h2>MAIL US</h2>
                  <a href="mailto:reachus@safepro.tech">
                    <span>
                      Sales &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;reachus@safepro.tech
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default Contactpage;
