import React, { useState, useEffect } from "react";
import request from "superagent";
import logo1 from "../assets/image/Reload.svg";
import icon1 from "../assets/image/Upload.svg";
import icon from "../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon4 from "../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon2 from "../assets/image/Trash.svg";
import icon3 from "../assets/image/Done.svg";
import "./CareerForm.css";
import { BaseURL } from "../BaseURL";

const CareerFormpage = () => {
  const [fileUploaded, setFileUploaded] = useState(false);
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const [fileLabels, setFileLabels] = useState({
    Resume: "Resume *",
  });

  const clearFile = (field) => {
    setFileLabels((prevLabels) => ({
      ...prevLabels,
      [field]: "Upload a file",
    }));
    setFileUploaded(false);
  };
  const updateLabel = (event, inputId) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 2097152 || selectedFile.size === 4096) {
        alert("File is too big!");
      } else {
        const newLabels = { ...fileLabels };
        newLabels[inputId] = selectedFile.name || "Please upload the file";
        setFileLabels(newLabels);
      }
    } else {
      alert("Please select a file");
      setFileLabels({
        Resume: "Resume *",
      });
      setFileUploaded(false);
    }
  };
  const [formValues, setFormValues] = useState({
    Full_Name: "",
    Resume: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });

  const [captchaNum1, setCaptchaNum1] = useState(0);
  const [captchaNum2, setCaptchaNum2] = useState(0);
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptchaNum1(num1);
    setCaptchaNum2(num2);
    setCaptchaAnswer("");
  };

  const ResumeFileChange = (event) => {
    const file = event.target.files[0];
    setFileLabels({ Resume: file.name });

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      setFormValues({
        ...formValues,
        Resume: reader.result,
      });
      console.log(reader.result);
    };
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCaptchaChange = (event) => {
    setCaptchaAnswer(event.target.value);
  };

  const handleSubmit = (event) => {
    console.log(formValues);
    event.preventDefault();

    if (
      !formValues.Full_Name ||
      !formValues.Email ||
      !formValues.Phone_Number
    ) {
      alert("Please fill out required fields and Upload you Resume.");
      console.log(formValues);
      return;
    }

    if (parseInt(captchaAnswer, 10) === captchaNum1 + captchaNum2) {
      formValues.windowTitle = document.title;
      request
        .post(`${BaseURL}/CareerForm/Career`)
        .send(formValues)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });

      setFormValues({
        Full_Name: "",
        Resume: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
      setFileLabels({
        Resume: "Resume *",
      });
      clearFile();
      generateCaptcha();
      setShowPopup(true);
    } else {
      alert("Captcha verification failed. Please try again.");
      generateCaptcha();
    }
  };
  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleReloadCaptcha = () => {
    generateCaptcha();
  };

  return (
    <div className="Form_container">
      <div className="Form">
        <div className="Left_section">
          <h1>
            <strong>YOU’VE GOT NO EXPERIENCE? DON’T WORRY</strong>
          </h1>
          <p>Please fill the form, we will get in touch with you soon.</p>
        </div>
        <div className="Right_section">
          <div className="Input_Holder">
            <input
              type="text"
              name="Full_Name"
              className="Input_Field"
              placeholder="Full Name *"
              value={formValues.Full_Name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="Email"
              className="Input_Field2"
              placeholder="Email *"
              value={formValues.Email}
              onChange={handleChange}
            />
          </div>
          <div className="Input_Holder">
            <input
              type="text"
              name="Phone_Number"
              className="Input_Field"
              placeholder="Phone Number *"
              value={formValues.Phone_Number}
              onChange={(event) => {
                handleChange(event);
                handleNumChangeM(event);
              }}
              required
            />
            <label className="Left_Align">
              <div className="Input_Field3">
                <label htmlFor="Resume" className="custom-file-label">
                  {fileLabels.Resume}
                </label>
                {fileUploaded ? (
                  <img
                    src={icon3}
                    alt="Check"
                    style={{ marginRight: "20px", cursor: "default" }}
                    className="custom-file-icon"
                  />
                ) : (
                  <img
                    src={icon1}
                    alt="Upload Resume"
                    className="custom-file-icon"
                  />
                )}
                {fileUploaded && (
                  <img
                    src={icon2}
                    alt="Delete"
                    onClick={clearFile}
                    style={{ cursor: "pointer" }}
                  />
                )}
                <input
                  type="file"
                  name="Resume"
                  className="form-input"
                  accept=".pdf"
                  onChange={(e) => {
                    ResumeFileChange(e);
                    setFileUploaded(true);
                    updateLabel(e, "Resume");
                  }}
                />
              </div>
            </label>
          </div>
          <textarea
            name="Message"
            className="Message"
            placeholder="Message"
            value={formValues.Message}
            onChange={handleChange}
          />

          <div className="Captcha_Block">
            <div className="numbers">
              {captchaNum1} <label>+</label> {captchaNum2} <label>=</label>
            </div>
            <input
              className="Input_Field_1"
              type="number"
              value={captchaAnswer}
              onChange={handleCaptchaChange}
            />
            <img
              className="Re"
              src={logo1}
              onClick={handleReloadCaptcha}
              alt="Reload"
            />
            <button className="Form_Submit" onClick={handleSubmit}>
              Submit
            </button>
          </div>
          {showPopup && (
            <>
              <div className="Overlay" />
              <div className="Popup">
                <img
                  src={icon4}
                  alt="Close"
                  className="CloseButton"
                  onClick={() => setShowPopup(false)}
                />
                <img src={icon} alt="Popup" />
                <span>
                  <h1>Thank You</h1>

                  <h3>
                    <p>The form was submitted successfully</p>
                  </h3>
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CareerFormpage;
