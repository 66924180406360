import "./Partnerspage.css";
import Header from "../Header/Header";
import { Helmet } from "react-helmet";
import PartnerRegistrationForm from "../PartnersFormpage/PartnersFormpage";
import Formpage from "../Form/Form";
import Footer from "../Footer/Footer";
import image from "../assets/image/partnerpageimage.jpg";

const Partnerspage = () => {
  return (
    <div>
      <Helmet>
        <title>Partners | Safepro</title>
        <meta
          name="description"
          content="Safepro provide the best video analytics, artificial intelligence, neural computing surveillance. Join us in integrity, Pursuit of Knowledge, Market Network & a clear Vision for Growth.
          "
        />
        <meta
          name="keyword"
          content="Partners , Safepro.
          "
        />
        <link rel="canonical" href="https://www.safepro.tech/aboutus.html" />
      </Helmet>
      <Header />
      <div className="Section_5">
        <div className="section_5_Body_Section">
          <div className="section_5_widths">
            <img src={image} alt="" />
          </div>
        </div>
      </div>
      <div className="section2">
        <div className="section_2Body_Section">
          <div className="section_2width">
            <div className="section_2_box">
              <h2>
                Coming together is a beginning. Keeping together is progress.
                Working together is success
              </h2>
              <p>
                We know that an individual always loses to a team. We're made of
                people and our biggest investments are in these people who make
                us innovate, succeedand scale greater heights with everyproduct
                we've launched. We'd love to connect withyou towards a mutually
                successful & sustainable relationship in making this world a
                safer & smarterplace to live in. Irrespective of the business
                sector youre in, what matters to us is Integrity, Pursuit of
                Knowledge, Market Network & a clear Vision for Growth. If youre
                looking at the exciting space of Artificial Intelligence,
                Unmanned Ground vehicles, Tactical Drones, Cognitive edge
                analytics,Neural computing surveillance and you're a strong
                player in your market, then we should work together.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="Section_3">
        <div className="Section_3_Body_Section">
          <div className="Section_3_width">
            <div className="section_3_content">
              <h3>Welcome to Safepro</h3>
              <p>
                We're home to disruptive technology in vision systems enabling
                cameras to be an autonomous preventive tool, decision support
                system, life saving device, analytical tool and much more.
                Artificial Intelligence, Cognitive surveillance, robotics,
                neural compute engines, IoT and embedded systems are something
                we have been doing for over a decade with products catering to
                varied sectors and an exhaustive customer base across Indian
                Subcontinent, Southeast Asia, and Africa. We thank you for your
                interest in our products and look forward to work with you. We
                request you kindly fill in the Channel Partner Registration Form
                which will help us to understand you better to position the
                right product fit from our portfolio of products and solutions.
                <span>
                  Coming together is a beginning. Keeping together is
                  progress.Working together is success
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <PartnerRegistrationForm />
      <Formpage />
      <Footer />
    </div>
  );
};

export default Partnerspage;
