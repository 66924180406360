import React from "react";
import "./Careerspage.css";
import { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import Header from "../Header/Header";
import CareerFormpage from "../CareerForm/CareerForm";
import Footer from "../Footer/Footer";
import image from "../assets/image/career1.jpg";
import image1 from "../assets/image/career_img01.jpg";
import image2 from "../assets/image/career_img02.jpg";
import image3 from "../assets/image/career_img03.jpg";
import image4 from "../assets/image/career_img04.jpg";
import image5 from "../assets/image/career_img05.jpg";
import image6 from "../assets/image/career_img06.jpg";
import image7 from "../assets/image/career_img07.jpg";
import image8 from "../assets/image/career_img08.jpg";
import image9 from "../assets/image/career_img09.jpg";
import image10 from "../assets/image/career_img10.jpg";
import image11 from "../assets/image/career_img11.jpg";
import image12 from "../assets/image/career_img12.jpg";
import image13 from "../assets/image/career_img13.jpg";
import image14 from "../assets/image/career_img14.jpg";
import image15 from "../assets/image/career_img15.jpg";
import image16 from "../assets/image/career_img16.jpg";
import image17 from "../assets/image/career_img17.jpg";
import image18 from "../assets/image/Explore_Internships_img.jpg";
import icon1 from "../assets/image/Circle.svg";
import icon2 from "../assets/image/Line_A.svg";
import icon3 from "../assets/image/LeftButton.svg";
import icon4 from "../assets/image/Right_Button.svg";

const Careerspage = () => {
  const [isTechnology, setIsTechnology] = useState(true);
  const [isSalesMarketing, setIsSalesMarketing] = useState(false);
  const targetRef = useRef(null);
  const widgetRef = useRef(null);

  const toggleTechnology = () => {
    setIsTechnology(true);
    setIsSalesMarketing(false);
  };

  const toggleSalesMarketing = () => {
    setIsTechnology(false);
    setIsSalesMarketing(true);
  };

  const handleLeft = () => {
    const element = widgetRef.current;
    element.scrollTo({
      left: element.scrollLeft - 450,
      behavior: "smooth",
    });
  };
  const handleRight = () => {
    const element = widgetRef.current;
    element.scrollTo({
      left: element.scrollLeft + 450,
      behavior: "smooth",
    });
  };

  const scroll = (targetRef) => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <div>
      <Helmet>
        <title>
          Latest Jobs Opening for Deep Learning, Image processing, Video
          Analytics developer
        </title>
        <meta
          name="description"
          content="Safepro is leading Video analytics company in India. Visit our website to know more about latest and current jobs openings deep learning, image processing, video analytics developer, Yolo, OpenCV, AI Internship, Computer Vision, python developer, QT developer, Flask developer, Full stack developer, C++, C# developer. 
          "
        />
        <meta
          name="keyword"
          content="Careers , Jobs at Safepro, Latest Jobs Opening, Job Opening, Safepro, Current Job Opening, deep learning, image processing, video analytics developer, Yolo, OpenCV, AI Internship, Computer Vision, python developer, QT developer, Flask developer, Full stack developer, C++, C# developer. 
          "
        />
        <link rel="canonical" href="https://www.safepro.tech/jobs.html" />
      </Helmet>
      <Header />
      <div className="Section_5">
        <div className="section_5_Body_Section">
          <div className="section_5_widths">
            <img src={image} alt="" />
          </div>
        </div>
      </div>
      <div className="Section_Content_Container">
        <section className="Section1">
          <div className="Content_Container">
            <h1>
              We’re still climbing, learning, falling, laughing!
              <br />
              We love what we’re doing & so having lots of fun.
            </h1>
            <p>
              An incident in the year 2005, wherein a burglary in our office in
              the presence of CCTV changed the way we looked at CCTV cameras
              forever. Security can never be forensic, but it must be
              preventive. This pushed us to build intelligence into cameras to
              make it preventive, intuitive and provide quicker actioning to
              save lives and assets. We achieved this with extracting data out
              of the video and applied science on this data that made these
              cameras ‘making sense of seeing’, and hence our mission is
              <strong>
                “It’s not the Video that’s important, but the Data in the
                video”.
              </strong>
            </p>
          </div>
        </section>
      </div>
      <div className="Section_Content_Container">
        <div className="Scroll_Image_Gallery">
          <div className="Image_List">
            <div className="wrapper1" id="Widget" ref={widgetRef}>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image1} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image2} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image3} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image4} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image5} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image6} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image7} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image8} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image9} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image10} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image11} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image12} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image13} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image14} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image15} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image16} alt="" />
                </div>
              </div>
              <div className="Card_Box">
                <div className="Card_Content">
                  <img srcSet={image17} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="Scroll_Button">
            <div className="Scroll_Button_Section">
              <div className="Circle">
                <img srcSet={icon1} alt="" />
              </div>
              <div className="Circle_Button">
                <img srcSet={icon3} onClick={handleLeft} alt="" />
              </div>
              <div className="Circle">
                <img srcSet={icon1} alt="" />
              </div>
              <div className="Line">
                <img srcSet={icon2} alt="" />
              </div>
              <div className="Circle">
                <img srcSet={icon1} alt="" />
              </div>
              <div className="Circle_Button">
                <img srcSet={icon4} onClick={handleRight} alt="" />
              </div>
              <div className="Circle">
                <img srcSet={icon1} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Section_Content_Container">
        <section className="Section1">
          <div className="New_Opening_Form">
            <div className="FormHeading">
              <h1>Openings with us</h1>
            </div>
            <div className="Swip_Block_Container">
              {isTechnology && (
                <div className="Swip_Button_Container">
                  <div className="Swipe">
                    <div className="Swipe_Pannel">
                      <button onClick={toggleTechnology}>Technology</button>
                    </div>
                    <div className="Swipe_Pannel_A">
                      <button onClick={toggleSalesMarketing}>
                        Sales & Marketing
                      </button>
                    </div>
                  </div>
                  <div className="Swipe_Content">
                    <h1>
                      YOU’RE WELL IN ANY OF THESE TECHNOLOGIES – WE WANT YOU
                    </h1>
                    <p>
                      AI, Image processing, Matlab, Python, Speed sensing,
                      Gesture control, Behavioral analysis, Pattern recognition,
                      Face recognition, Object identification, R&D, Robotics,
                      Intelligent electronics, Scientist, Raspberry Pi, Arduino,
                      Intel NUC, Neural networks, Video surveillance, Video
                      analytics, Smart City, Machine learning, deep learning
                      algorithms, Yolo, C, C++, Embedded programmer, OpenCV,
                      Convolutional neural network acceleration, Intel® Deep
                      Learning Inference Accelerator (Intel® DLIA), FPGAs, SIP
                      audio streaming, Cognitive Science, ANPR, Android, iOS,
                      Cakephp, UI & UX, LoRa, network video surveillance cameras
                      & more.
                    </p>
                  </div>
                </div>
              )}

              {isSalesMarketing && (
                <div className="Swip_Button_Container">
                  <div className="Swipe">
                    <div className="Swipe_Pannel_A">
                      <button onClick={toggleTechnology}>Technology</button>
                    </div>
                    <div className="Swipe_Pannel">
                      <button onClick={toggleSalesMarketing}>
                        Sales & Marketing
                      </button>
                    </div>
                  </div>
                  <div className="Swipe_Content">
                    <h1>
                      IT ISN’T DONE UNTIL WE SHIP! SALES & MARKETING GENIUSES –
                      WE WANT YOU
                    </h1>
                    <p>
                      If you can sell i.e. strong traction/exp of selling with
                      knowledge of IT software or IT hardware or Electronics or
                      Security or Smart City projects or eGovernance projects or
                      any Govt. oriented projects along with passion & inherent
                      craving for sales & marketing, then talk to us.
                    </p>
                  </div>
                </div>
              )}

              <div className="Button_For_Apply">
                <button onClick={() => scroll(targetRef)}>Apply</button>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="section_10">
        <div className="section_10_Body_Section">
          <div className="section_10_Body_Section_parts">
            <div className="catalog_button">
              <button className="button_1">
                <a
                  href="https://imtechready.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Explore Internships
                  <img src={image18} alt="" />
                </a>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div ref={targetRef}></div>
      <CareerFormpage />
      <Footer />
    </div>
  );
};

export default Careerspage;
