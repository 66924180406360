import "./ITMS.css";
import { Helmet } from "react-helmet";
import Header from "../../Header/Header";
import EProductspage from "../../ExtraProductpage/EProductspage";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";

const ITMS = () => {
  return (
    <div>
      <Helmet>
        <title>
          Face Recognition Attendance Management Software System for Schools,
          Colleges, Educational Institutions
        </title>
        <meta
          name="description"
          content="	Safepro offers easier and better attendance management system using face recognition technology. Full automated student attendance software system for Educational Institutions, schools, colleges in India.
          "
        />
        <meta
          name="keyword"
          content="school attendance software, school attendance management system, student attendance management system, India, college attendance management system, attendance system for schools, attendance system for colleges, face recognition attendance system for schools, face recognition attendance system for college
          "
        />
        <link
          rel="canonical"
          href="https://www.safepro.tech/Intelligent-Traffic-Management-System"
        />
      </Helmet>
      <Header />
      <EProductspage />
      <section>
        <div className="background">
          <h1>Coming Soon !!!</h1>
        </div>
      </section>

      <Formpage />
      <Footer />
    </div>
  );
};

export default ITMS;
