import React, { Component } from "react";
import "./PartnersFormpage.css";
import request from "superagent";
import { BaseURL } from "../BaseURL";

class PartnerRegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Success: false,
      Partner_Form: {
        First_Name: "",
        Last_Name: "",
        Phone_Number: "",
        Email: "",
        Address_1: "",
        Address_2: "",
        country: "",
        state: "",
        city: "",
        zip_code: "",
        company_name: "",
        Website: "",
        gst_tax_number: "",
        company_registration_number: "",
      },
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    request
      .post(`${BaseURL}/PartnerForm/Partner`)
      .send(this.state.Partner_Form)
      .set("Accept", "application/json")
      .then((response) => {
        // console.log(response.body.message);
        // console.log(this.state.Partner_Form);
      })
      .catch((error) => {
        console.error("Error sending Email:", error);
      });
    this.setState({ Success: true,Partner_Form: {
      First_Name: "",
      Last_Name: "",
      Phone_Number: "",
      Email: "",
      Address_1: "",
      Address_2: "",
      country: "",
      state: "",
      city: "",
      zip_code: "",
      company_name: "",
      Website: "",
      gst_tax_number: "",
      company_registration_number: "",
    }, });
    
      
    
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      Partner_Form: {
        ...prevState.Partner_Form,
        [name]: value,
      },
    }));
  };

  render() {
    const { Success } = this.state;
    const { Partner_Form } = this.state;
    return (
      <div className="Form_Container_Section">
        <div className="Form_Container_width">
          <div className="Form_Container_parts">
            <div className="Form_Header">
              <h1>Channel Partner Registration</h1>
            </div>
            <div className="Form_Block">
              <form className="PartnerForm" onSubmit={this.handleSubmit}>
                <div className="InputField_area_1">
                  <p>
                    Business Owner <span>*</span>
                  </p>
                  <div className="InputField_area_5_1">
                    <div className="InputField_box_1">
                      <input
                        type="text"
                        className="form-input"
                        name="First_Name"
                        placeholder="First Name"
                        required="required"
                        value={Partner_Form.First_Name}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="InputField_box_1">
                      <input
                        type="text"
                        className="form-input"
                        name="Last_Name"
                        placeholder="Last Name"
                        required="required"
                        value={Partner_Form.Last_Name}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="InputField_area_2">
                  <p>
                    Contact Number <span>*</span>
                  </p>{" "}
                  <div className="InputField_box_2">
                    <input
                      className="form-input"
                      type="number"
                      maxLength="10"
                      name="Phone_Number"
                      placeholder="Phone Number"
                      required="required"
                      value={Partner_Form.Phone_Number}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="InputField_area_3">
                  <p>
                    E-mail <span>*</span>
                  </p>{" "}
                  <div className="InputField_box_2">
                    <input
                      type="email"
                      className="form-input"
                      name="Email"
                      placeholder="Email"
                      required="required"
                      value={Partner_Form.Email}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="InputField_area_4">
                  <p>
                    Address <span>*</span>
                  </p>

                  <div className="InputField_box_3">
                    <input
                      type="text"
                      className="form-input"
                      name="Address_1"
                      placeholder="Line 1"
                      required="required"
                      value={Partner_Form.Address_1}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="InputField_box_3">
                    <input
                      type="text"
                      className="form-input"
                      name="Address_2"
                      placeholder="Line 2"
                      required="required"
                      value={Partner_Form.Address_2}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="InputField_area_5">
                  <div className="InputField_box_4">
                    <input
                      type="text"
                      className="form-input"
                      name="country"
                      placeholder="Country"
                      required="required"
                      value={Partner_Form.country}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="InputField_box_4">
                    <input
                      type="text"
                      className="form-input"
                      name="state"
                      placeholder="State / Province"
                      required="required"
                      value={Partner_Form.state}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="InputField_box_5">
                    <input
                      type="text"
                      className="form-input"
                      name="city"
                      placeholder="City"
                      required="required"
                      value={Partner_Form.city}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="InputField_box_5">
                    <input
                      className="form-input"
                      type="number"
                      maxLength="12"
                      name="zip_code"
                      placeholder="Postal / Zip Code"
                      required="required"
                      value={Partner_Form.zip_code}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="InputField_area_6">
                  <p>
                    Company/Firm Name <span>*</span>
                  </p>{" "}
                  <div className="InputField_box_2">
                    <input
                      type="text"
                      className="form-input"
                      name="company_name"
                      placeholder="Company/Firm Name"
                      required="required"
                      value={Partner_Form.company_name}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="InputField_area_7">
                  <p>
                    Website <span>*</span>
                  </p>{" "}
                  <div className="InputField_box_2">
                    <input
                      type="text"
                      className="form-input"
                      name="Website"
                      placeholder="Website"
                      required="required"
                      value={Partner_Form.Website}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="InputField_area_8">
                  <p>
                    GST/Tax Registration Number <span>*</span>
                  </p>{" "}
                  <div className="InputField_box_2">
                    <input
                      className="form-input"
                      type="number"
                      maxLength="12"
                      name="gst_tax_number"
                      placeholder="GST/Tax Registration Number"
                      required="required"
                      value={Partner_Form.gst_tax_number}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="InputField_area_9">
                  <p>
                    Company Registration Number <span>*</span>
                  </p>{" "}
                  <div className="InputField_box_2">
                    <input
                      type="text"
                      className="form-input"
                      name="company_registration_number"
                      placeholder="Company Registration Number"
                      required="required"
                      value={Partner_Form.company_registration_number}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="button">
                  <div className="Button1">
                    <button type="submit">Submit</button>
                    <div className="SucessMsg">
                      {Success && <p>We will reach you shortly.</p>}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerRegistrationForm;
