import { useState } from "react";
import { Helmet } from "react-helmet";
import logo1 from "../../assets/image/vigilar_logo.svg";
import logo2 from "../../assets/image/vijilarbanner.jpg";
import image from "../../assets/image/vigilar_pole.jpg";
import icon1 from "../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon3 from "../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import Header from "../../Header/Header";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";
import Productspage from "../../Productspage/Productspage";
import request from "superagent";
import { BaseURL } from "../../BaseURL";

const Vigilar = () => {
  const [isVideo, setIsVideo] = useState(false);
  const [showForm, setForm] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleCatalog = (event) => {
    event.preventDefault();
    if (
      [
        formValues.First_Name,
        formValues.Last_Name,
        formValues.Email,
        formValues.Phone_Number,
        formValues.Message,
      ].some((field) => field?.trim() !== "")
    ) {
      setForm(false);
      setShowPopup(true);
      formValues.windowTitle = document.title;
      request
        .post(`${BaseURL}/Mail/Vigilar`)
        .send(formValues)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });

      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
    }
  };

  const handleCheckboxChange = () => {
    setIsVideo(!isVideo);
  };
  return (
    <div>
      <Helmet>
        <title>
          Smart City Solutions | Vigilar | Smart Pole Technology System
        </title>
        <meta
          name="description"
          content="Safepro Vigilar is an artificial intelligence smart pole technology system with CCTV, environmental sensors, video analytics, and emergency response system for smart city soltuions, parks, smart roads & streets in India.
          "
        />
        <meta
          name="keyword"
          content=" Smart poles, Smart poles in India, Smart City Technology, Smart Pole System, smart roads, artificial intelligence, safe city, smart LED street lights, smart cities in Telangana, smart cities in Kerala, Smart city Dehradun, Safe City,  CCTV for parks , Vigilar, Safepro.
          "
        />
        <link
          rel="canonical"
          href="https://www.safepro.tech/smartcity/vigilar.html"
        />
      </Helmet>
      <Header />
      <Productspage />
      <section className="Sec">
        <div className="Body_Section">
          <div className="Body_Section_parts">
            <div className="left_Body_Section_parts">
              <img src={logo1} alt="" />
              <p>
                One Pole <br /> Many Possibilites
              </p>
            </div>
            <div className="right_Body_Section_parts">
              <img src={logo2} alt="" />
              <div className="Body_Section_video">
                <input type="checkbox" onClick={handleCheckboxChange} />
                <p>Click here for the video</p>
              </div>
              {isVideo && (
                <div className="video">
                  <iframe
                    src="https://www.youtube.com/embed/0tnaPO5nz9o?autoplay=1&&mute=1&playlist=0tnaPO5nz9o&loop=1"
                    allow="autoplay"
                    title="Vigilar"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowFullScreen
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <br />
      <div className="section_2">
        <div className="section_2_Body_Section">
          <div className="section_2_width">
            <div className="section_2_content">
              <h1>Vigilar</h1>
              <p>
                Safepro Vigilar is a perfect combination of video electronics,
                IOT, Artificial Intelligence, video and data analytics, sensors,
                surveillance and alert systems, all working in tandem to thwart
                crimes on the streets and to keep the law enforcement agencies
                alerted in real time, Of happenings on the streets. Safepro
                Vigilar, in its design, form and factor, is power- packed with
                features that align seamlessly to give law enforcement agencies
                a valuable tool to track and pin down criminals well before the
                crime is committed, thereby securing our streets. uto and SOS
                triggered, Safepro Vigilar instantly connects the victim to the
                police control room, nearby iurisdictional police station and
                police mobile units close by with its audio, video streaming and
                GPS features. It also raises a 110 decibel loud alarm alerting
                people nearby, thereby thwarting any incident likely to happen.
                Safepro Vigilar thus prevents crimes and secures people on the
                street. It's not iust security, but a smart device for a smart
                city It also comes equipped with additional features such as
                earthquake sensors, pollution monitoring of C02, fine dust and
                noise levels, temperature monitoring and a public address system
                to broadcast general messages to the public.
              </p>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup">
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon1} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
                <p>You will receive a catalog on your email</p>
              </h3>
            </span>
          </div>
        </>
      )}
      {showForm && (
        <>
          <div className="uc_model">
            <div className="uc_content">
              <div className="uc_header">
                <p className="uc_heading_one">Please fill this form</p>
                <span>
                  <img
                    alt=""
                    srcSet={icon4}
                    height="30px"
                    onClick={() => {
                      setForm(false);
                    }}
                  />
                </span>
                <p className="uc_heading_two">
                  We will share you the product catalog through your email.
                </p>
              </div>
              <div className="uc_body">
                <form onSubmit={handleCatalog}>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="First_Name"
                          value={formValues.First_Name}
                          name="First_Name"
                          className="uc-form-control"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Last_Name"
                          value={formValues.Last_Name}
                          name="Last_Name"
                          className="uc-form-control"
                          placeholder="Last Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="email"
                          id="Email"
                          value={formValues.Email}
                          name="Email"
                          className="uc-form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Phone_Number"
                          maxLength="10"
                          value={formValues.Phone_Number}
                          name="Phone_Number"
                          className="uc-form-control"
                          placeholder="Phone number"
                          required
                          onChange={(e) => {
                            handleChange(e);
                            handleNumChangeM(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group">
                    <div className="">
                      <textarea
                        id="Message"
                        value={formValues.Message}
                        name="Message"
                        className="uc-form-control"
                        rows="6"
                        maxLength="3000"
                        placeholder="Write your message"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="uc-submit-button">
                    <button type="submit">
                      <span>Submit</span>
                      <img alt="" srcSet={icon5} height="20px" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Catalog_Container">
        <div className="Body_Content_Forth_Inner_Buttons">
          <button
            className="Body_Content_Forth_Button_One1"
            onClick={() => {
              setForm(true);
            }}
          >
            <strong>Product catalog</strong> &nbsp;
            <img alt="" srcSet={icon3} />
          </button>
        </div>
      </div>
      <div className="Section5">
        <div className="Section5_Body_Section">
          <div className="Section5_width">
            <img src={image} alt="" />
          </div>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default Vigilar;
