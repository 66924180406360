import { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../Header/Header";
import EProductspage from "../../ExtraProductpage/EProductspage";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";
import icon1 from "../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon3 from "../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import image from "../../assets/image/time_banner.jpg";
import request from "superagent";
import { BaseURL } from "../../BaseURL";

const Time = () => {
  const [showForm, setForm] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleCatalog = (event) => {
    event.preventDefault();
    if (
      [
        formValues.First_Name,
        formValues.Last_Name,
        formValues.Email,
        formValues.Phone_Number,
        formValues.Message,
      ].some((field) => field?.trim() !== "")
    ) {
      setForm(false);
      setShowPopup(true);

      formValues.windowTitle = document.title;
      request
        .post(`${BaseURL}/Mail/Catalog`)
        .send(formValues)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });

      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
    }
  };
  return (
    <div>
      <Helmet>
        <title>
          Face Recognition Attendance Management Software System for Schools,
          Colleges, Educational Institutions
        </title>
        <meta
          name="description"
          content="	Safepro offers easier and better attendance management system using face recognition technology. Full automated student attendance software system for Educational Institutions, schools, colleges in India.
          "
        />
        <meta
          name="keyword"
          content="school attendance software, school attendance management system, student attendance management system, India, college attendance management system, attendance system for schools, attendance system for colleges, face recognition attendance system for schools, face recognition attendance system for college
          "
        />
        <link
          rel="canonical"
          href="https://www.safepro.tech/videoanalytics/facerecognition/attendance-management-system-educational-institution.php"
        />
      </Helmet>
      <Header />
      <EProductspage />
      <section>
        <div className="Time_Attendance">
          <div className="first_Section">
            <div className="Left">
              <img src={image} alt="Admetrics" />
            </div>
            <div className="Left_Mobile">
              <img src={image} alt="Admetrics" />
            </div>
            <div className="right">
              <h1>Time & Attendance for Education</h1>
              <a href="/videoanalytics/facerecognition/attendance-management-system-educational-institution.php">
                Enabling efficiency in education through vision engineering
              </a>
              <p>
                Simple, yet powerful face recognition time & attendance
                management system driven on facial recognition system, designed
                & built to enable your existing cameras to recognise your
                teachers or students while they walk into the premises be it
                single or many at a time. Safepro video analytics enabling key
                performance indicators making every class in your school
                measured for performance, efficacy and well informed data
                analytics tool. A defined visual decision support system for
                affirmative decision, synchronizing student learning with
                teacher efficiency, attendance, safety & security together.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section_3">
        <div className="section_3_Body_Section">
          <div className="section_3_width">
            <div className="section_3_content">
              <h4 className="Text">
                We bring artificial intelligence with cognitive cameras
                addressing contactless and effortless student and faculty
                attendance driven on facial recognition. Many more analytical
                assessments are integrated such as periodical attendance of
                students, student behavioral monitoring, teacher-student
                interactions, student concentration analytics
              </h4>
              <div className="section_3_content_1">
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
              </div>
              <div className="section_3_content_2">
                <div className="section_3_content_2_1">
                  <p>
                    Teacher attendance system driven on Face recognition on
                    CCTVs in the school
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Student attendance system driven on Face recognition on
                    CCTVs in the school
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Automated attendance reports with face time stamp at every
                    school
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Scheduled emailers of attendance periodical reports to
                    district and state heads
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Classroom teaching quality experience monitoring</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Integration of this AI system with your existing
                    applications including SATS
                  </p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Student attendance missing alerts on WhatsApp</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>
                    Unified central command centre dashboard application at
                    state level for monitoring, tracking & storage
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup">
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon1} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
                <p>You will receive a catalog on your email</p>
              </h3>
            </span>
          </div>
        </>
      )}
      {showForm && (
        <>
          <div className="uc_model">
            <div className="uc_content">
              <div className="uc_header">
                <p className="uc_heading_one">Please fill this form</p>
                <span>
                  <img
                    alt=""
                    srcSet={icon4}
                    height="30px"
                    onClick={() => {
                      setForm(false);
                    }}
                  />
                </span>
                <p className="uc_heading_two">
                  We will share you the product catalog through your email.
                </p>
              </div>
              <div className="uc_body">
                <form onSubmit={handleCatalog}>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="First_Name"
                          value={formValues.First_Name}
                          name="First_Name"
                          className="uc-form-control"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Last_Name"
                          value={formValues.Last_Name}
                          name="Last_Name"
                          className="uc-form-control"
                          placeholder="Last Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="email"
                          id="Email"
                          value={formValues.Email}
                          name="Email"
                          className="uc-form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Phone_Number"
                          maxLength="10"
                          value={formValues.Phone_Number}
                          name="Phone_Number"
                          className="uc-form-control"
                          placeholder="Phone number"
                          required
                          onChange={(e) => {
                            handleChange(e);
                            handleNumChangeM(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group">
                    <div className="">
                      <textarea
                        id="Message"
                        value={formValues.Message}
                        name="Message"
                        className="uc-form-control"
                        rows="6"
                        maxLength="3000"
                        placeholder="Write your message"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="uc-submit-button">
                    <button type="submit">
                      <span>Submit</span>
                      <img alt="" srcSet={icon5} height="20px" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Catalog_Container">
        <div
          className="Body_Content_Forth_Inner_Buttons"
          style={{ paddingBottom: "60px" }}
        >
          <button
            className="Body_Content_Forth_Button_One1"
            onClick={() => {
              setForm(true);
            }}
          >
            <strong>Product catalog</strong> &nbsp;
            <img alt="" srcSet={icon3} />
          </button>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default Time;
