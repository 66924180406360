import React from "react";
import "./ComingSoon.css";
const ComingSoon = () => {
  return (
    <div className="Coming_Soon_Text">
      <div className="Heading_Container">
        <h1>Updating...</h1>
        <p>Please check back later!</p>
      </div>
    </div>
  );
};

export default ComingSoon;
