import { useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../Header/Header";
import EProductspage from "../../ExtraProductpage/EProductspage";
import Formpage from "../../Form/Form";
import Footer from "../../Footer/Footer";
import icon1 from "../../assets/Facesense_LandingPage_Img/CheckIcon.svg";
import icon3 from "../../assets/Facesense_LandingPage_Img/DownloadIcon.svg";
import icon4 from "../../assets/Facesense_LandingPage_Img/Cancel.svg";
import icon5 from "../../assets/Facesense_LandingPage_Img/SendIcon.svg";
import image from "../../assets/image/video_ana_retailytics.jpg";
import request from "superagent";
import { BaseURL } from "../../BaseURL";

const Retailytics = () => {
  const [showForm, setForm] = useState(false);
  const [formValues, setFormValues] = useState({
    First_Name: "",
    Last_Name: "",
    Email: "",
    Phone_Number: "",
    Message: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleNumChangeM = (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    const limit1 = 10;
    setFormValues({
      ...formValues,
      Phone_Number: numericValue.slice(0, limit1),
    });
  };
  const [showPopup, setShowPopup] = useState(false);
  const handleCatalog = (event) => {
    event.preventDefault();
    if (
      [
        formValues.First_Name,
        formValues.Last_Name,
        formValues.Email,
        formValues.Phone_Number,
        formValues.Message,
      ].some((field) => field?.trim() !== "")
    ) {
      setForm(false);
      setShowPopup(true);

      formValues.windowTitle = document.title;
      request
        .post(`${BaseURL}/Mail/Retailytics`)
        .send(formValues)
        .set("Accept", "application/json")
        .then((response) => {
          console.log(response.body.message);
        })
        .catch((error) => {
          console.error("Error sending Email:", error);
        });

      setFormValues({
        First_Name: "",
        Last_Name: "",
        Email: "",
        Phone_Number: "",
        Message: "",
      });
    }
  };
  return (
    <div>
      <Helmet>
        <title>
          Artificial Intelligence For Retails store | People Counting |
          Intrusion Alert | Dwell time analytics | Video analytics for retail
          store
        </title>
        <meta
          name="description"
          content="AI solutions for retail stores are driving growth and efficiency. Our AI based vision systems helps in assessment of various such as people counting, interactions mapping, Intrusion alerts and also customer experience assessment at billing counters.
          "
        />
        <meta
          name="keyword"
          content="Artificial intelligence for retails store, people counting, intrusion alert, interactions, cctv for retail stores, CCTV Camera for Retail Stores,Security camera for Retail store, Surveillance camera for retail store
          "
        />
        <link
          rel="canonical"
          href="https://www.safepro.tech/cctv/retailstores/people-counting.html"
        />
      </Helmet>
      <Header />
      <EProductspage />
      <section>
        <div className="Time_Attendance">
          <div className="first_Section">
            <div className="Left">
              <img src={image} alt="Admetrics" />
            </div>
            <div className="Left_Mobile">
              <img src={image} alt="Admetrics" />
            </div>
            <div className="right">
              <h1>Retailytics</h1>
              <a href="/face-recognition-time-attendance-System.php">
                Retailytics, visual data driven tool for your retail store
              </a>
              <p>
                With every increasing competition and customer expectations,
                retail stores are doing everything possible to give the best of
                customer experience in their stores. An excellent customer
                experience in the store in the only way today brick & mortar
                stores rely upon to counter the ever diminishing prices on
                ecommerce sites. Better customer experiences rely on strong data
                for an effective decision making. We at Safepro realise that
                data is the new oil. Retailytics brings a host of features
                aiding retail stores to deliver the best of customer experiences
                backed by rich data coupled with visual analytics.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="section_3">
        <div className="section_3_Body_Section">
          <div className="section_3_width">
            <div className="section_3_content">
              <h4 className="Text">
                Our AI based vision systems helps in assessment of various KPIs
                to ensure you only make the right decisions. Analysing data to
                measure in your retail store leads to better performing
                business. Number of visitors to your store, dwell time of people
                in the store, tracking regular customers with integrated loyalty
                programs, peak and off-peak business times, tracking customer
                happiness quotient, crowd analytics and more are few of them we
                have on our Retailytics platform to give our retail store video
                analytics. With a unified dashboard, these video analytics are
                syncronised into data analytics to give you a rich decision
                making tool to monitor, operate, grow productivity at all your
                stores across different geographies. Get the edge insights and
                visual analytics with our AI based precision cameras in
                Retailytics to track and analyse the count the footfalls into
                your store, map the product interactions of your customer across
                the store, the average dwell time of customers in your store,
                analyse customer experience and manage security too like
                intrusion & trespassing detection and staff attendance
                management on face recognition. It’s a complete retail analytics
                product package helping you take the right decisions to make a
                store a delightful experience for your customers.
              </h4>
              <div className="section_3_content_1">
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
                <div className="section_3_content_1_1">
                  <p>
                    <span>&#x25C6;</span>
                  </p>
                </div>
              </div>
              <div className="section_3_content_2">
                <div className="section_3_content_2_1">
                  <p>People counting for store footfalls analysis</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Customer product interactions mapping</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Dwell time of customers within the store</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Shoplifting tracking & alerts</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Intrusion & breach detection & alerts</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Employee attendance management on face recognition</p>
                </div>
                <div className="section_3_content_2_1">
                  <p>Customer experience assessment at billing counters</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <>
          <div className="Overlay" />
          <div className="Popup">
            <img
              src={icon4}
              alt="Close"
              className="CloseButton"
              onClick={() => setShowPopup(false)}
            />
            <img src={icon1} alt="Popup" />
            <span>
              <h1>Thank You</h1>

              <h3>
                <p>The form was submitted successfully</p>
                <p>You will receive a catalog on your email</p>
              </h3>
            </span>
          </div>
        </>
      )}
      {showForm && (
        <>
          <div className="uc_model">
            <div className="uc_content">
              <div className="uc_header">
                <p className="uc_heading_one">Please fill this form</p>
                <span>
                  <img
                    alt=""
                    srcSet={icon4}
                    height="30px"
                    onClick={() => {
                      setForm(false);
                    }}
                  />
                </span>
                <p className="uc_heading_two">
                  We will share you the product catalog through your email.
                </p>
              </div>
              <div className="uc_body">
                <form onSubmit={handleCatalog}>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="First_Name"
                          value={formValues.First_Name}
                          name="First_Name"
                          className="uc-form-control"
                          placeholder="First Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Last_Name"
                          value={formValues.Last_Name}
                          name="Last_Name"
                          className="uc-form-control"
                          placeholder="Last Name"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group" style={{ display: "flex" }}>
                    <div
                      className="Field"
                      style={{ width: "45%", paddingRight: "120px" }}
                    >
                      <div className="fcf-input-group_half">
                        <input
                          type="email"
                          id="Email"
                          value={formValues.Email}
                          name="Email"
                          className="uc-form-control"
                          placeholder="Email"
                          required
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="Field" style={{ width: "45%" }}>
                      <div className="fcf-input-group_half">
                        <input
                          type="text"
                          id="Phone_Number"
                          maxLength="10"
                          value={formValues.Phone_Number}
                          name="Phone_Number"
                          className="uc-form-control"
                          placeholder="Phone number"
                          required
                          onChange={(e) => {
                            handleChange(e);
                            handleNumChangeM(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="uc-form-group">
                    <div className="">
                      <textarea
                        id="Message"
                        value={formValues.Message}
                        name="Message"
                        className="uc-form-control"
                        rows="6"
                        maxLength="3000"
                        placeholder="Write your message"
                        required
                        onChange={handleChange}
                      ></textarea>
                    </div>
                  </div>
                  <div className="uc-submit-button">
                    <button type="submit">
                      <span>Submit</span>
                      <img alt="" srcSet={icon5} height="20px" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="Catalog_Container">
        <div
          className="Body_Content_Forth_Inner_Buttons"
          style={{ paddingBottom: "60px" }}
        >
          <button
            className="Body_Content_Forth_Button_One1"
            onClick={() => {
              setForm(true);
            }}
          >
            <strong>Product catalog</strong> &nbsp;
            <img alt="" srcSet={icon3} />
          </button>
        </div>
      </div>
      <Formpage />
      <Footer />
    </div>
  );
};

export default Retailytics;
